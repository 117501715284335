<template>
  <div id="gifteelist-view">
    <section class="bg-light">
      <main></main>
      <div class="col-md-12">
        <div class="findgft">
          <router-link
            to="/home"
            class="sm_megamenu_head sm_megamenu_drop "
            id="sm_megamenu_286"
            href=""
          >
            <h1 class="display-4">Giftee List</h1></router-link
          >
        </div>
        <div class="d-flex justify-content-center">
          <img
            src="@/assets/site/images/loader.gif"
            v-if="loader === true"
            class="img-fluid  "
            alt
          />
        </div>
      </div>
      <div class="container-fluid pt-5"></div>
      <!-- <div class="container-fluid">
         <toggle-button :value="wishlist_status== 'Private' ? true: false" @change="changeStatus(wishlist_status)"     color="#c8730e"  />
        <hr />
      </div> -->
      <p v-if="imageshow">
        <span class="d-flex justify-content-center"
          ><img
            class="img-fluid text-center"
            src="@/assets/site/images/Group1.svg"
            alt=""
        /></span>
      </p>
      <div class="container-fluid">
        <div>
          <b-tabs content-class="mt-3">
            <b-tab active>
              <template #title>
                <strong><h2>Occassions</h2></strong>
              </template>
              <div class="row">
                <div class="col-md-3">
                  <ul>
                    <li
                      v-for="contact in contacts"
                      class="sm_megamenu_title ml-4 my-3"
                      @click="showOccasionList(contact)"
                    >
                      <!-- <router-link v-for="contact in contacts" class="sm_megamenu_title ml-4 my-3" to="occasion_wishlist"> -->
                      <h2>
                        <span class="col-md-3">
                          <img
                            :src="contact.photo"
                            width="30"
                            height="30"
                            class="img-fluid"
                            alt=""
                          />
                        </span>
                        {{ contact.contact_name }}
                      </h2>
                      <!-- </router-link> -->
                    </li>
                  </ul>
                </div>
                <div class="col-md-9">
                  <gift-view
                    v-if="show"
                    v-bind:giftproducts="giftproducts"
                  ></gift-view>
                </div>
              </div>
            </b-tab>
            <b-tab v-if="pet_wishlist.length > 0">
              <template #title>
                <strong><h2>Pets Wishlist</h2></strong>
              </template>
              <div class="row">
                <div class="col-md-3">
                  <ul>
                    <li
                      v-for="pet in pet_wishlist"
                      class="sm_megamenu_title ml-4 my-3"
                      @click="showOccasionList(pet)"
                    >
                      <!-- <router-link v-for="contact in contacts" class="sm_megamenu_title ml-4 my-3" to="occasion_wishlist"> -->
                      <h2>
                        <span class="col-md-3">
                          <img
                            :src="pet.pet_image"
                            width="30"
                            height="30"
                            class="img-fluid"
                            alt=""
                          />
                        </span>
                        {{ pet.petwizardDetails.name }}
                      </h2>
                      <!-- </router-link> -->
                    </li>
                  </ul>
                </div>
                <div class="col-md-9">
                  <gift-view
                    v-if="show"
                    v-bind:giftproducts="giftproducts"
                  ></gift-view>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>

      <div class="container">
        <hr />
      </div>
    </section>

    <!-- End Footer -->
  </div>
</template>
<script>
import GiftView from "@/views/site/user/GiftView";

export default {
  name: "GifteelistView",
  data() {
    return {
      loader: false,
      errors: [],
      pages: [],
      contacts: [],
      giftproducts: [],
      imageshow: false,
      wishlist_status: null,
      show: false,
      pet_wishlist: [],
    };
  },
  components: { GiftView },
  created() {
    // this.getGifteelists();
    this.getContacts();
    this.getPets();
  },
  methods: {
    getContacts() {
      this.errors = [];
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/usercontact/getdata";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id,
          page: 1,
          size: 100,
          filter: null,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.contacts = data.contact;
          // console.log(this.contacts);
          // this.wishlist_status=data.wishlist_status;
          // if (this.wishlistproducts.length == 0) {
          //     this.imageshow = true;
          // }
        });
    },
    getPets() {
      this.errors = [];
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url = process.env.VUE_APP_URL + "customer/pet/getPetwizardDetails";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.pet_wishlist = data.petwizardDetails;
          // console.log(this.contacts);
          // this.wishlist_status=data.wishlist_status;
          // if (this.wishlistproducts.length == 0) {
          //     this.imageshow = true;
          // }
        });
    },
    getGifteelists() {
      this.errors = [];
      this.loader = true;
      var token = localStorage.getItem("userData");
      var userdata_array = JSON.parse(token);
      var user_id = userdata_array.user.id;
      //  var api_token = userdata_array.token;
      let method_type = "";
      let fetch_url = "";
      method_type = "post";
      fetch_url =
        process.env.VUE_APP_URL + "customer/usercontact/getContactGift";
      fetch(fetch_url, {
        method: method_type,
        headers: {
          "content-type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
        },
        body: JSON.stringify({
          user_id: user_id,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          this.loader = false;
          this.errors = [];
          this.contacts = data.usercontact;
          this.pet_wishlist = data.pets;
          // console.log(this.contacts);
          // this.wishlist_status=data.wishlist_status;
          // if (this.wishlistproducts.length == 0) {
          //     this.imageshow = true;
          // }
        });
    },
    showGiftList(contact) {
      // this.$router.replace({
      //     name: 'FriendswishlistView',
      //     params: {
      //       wishlist:contact.wishlist,
      //     }
      // })
      this.show = true;
      this.giftproducts = contact.gifts;
    },
    showOccasionList(contact) {
      var page_name = this.$route.meta.title;
      var path = this.$route.path;
      this.$router.replace({
        name: "Occasiongift",
        params: {
          occasion_list: contact.occasion_lists,
          page_name: page_name,
          path: path,
        },
      });
      // this.show = true;
      // this.wishlistproducts = contact.wishlist;
    },
  },
};
</script>
